import React from "react"
import { filterByLang } from "@components/utils"
import UnderPageHero from "@components/common/underPageHero"
import Main from "./main"
import BackToTop from "@components/common/backToTop"
import Footer from "@components/common/footer"
import DrawerMenu from "@components/common/drawerMenu"
import { EntryTemplateContext } from "@/gatsby-node/createEntries"
import { UseToggleLanguage } from "@components/hooks"
// ______________________________________________________
//
type Props = EntryTemplateContext &
  UseToggleLanguage & {
    className?: string
    title: string
  }
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <UnderPageHero
      lang={props.lang}
      toggleToEN={props.toggleToEN}
      toggleToJA={props.toggleToJA}
    />
    <Main
      title={props.title}
      body={
        props.edges.filter(filterByLang(props.lang))[0]?.node?.body
          ?.childMarkdownRemark?.html ?? ""
      }
    />
    <BackToTop />
    <Footer
      lists={
        props.lang === "ja"
          ? (props.site?.siteMetadata?.footerLinkListsJA as any)
          : (props.site?.siteMetadata?.footerLinkListsEN as any)
      }
    />
    <DrawerMenu
      lang={props.lang}
      toggleToEN={props.toggleToEN}
      toggleToJA={props.toggleToJA}
    />
  </div>
)
// ______________________________________________________
//
export default Component
