import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Title from "@components/common/title/h1"
import Body from "./body"
// ______________________________________________________
//
type Props = {
  className?: string
  title: string
  body: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <Title figurePosition="right">{props.title}</Title>
    <Body body={props.body} />
  </div>
)
// ______________________________________________________
//
const pcStyle = `
max-width: 970px;
padding: 40px;
margin: 0 auto;
border-bottom: 1px solid rgba(196, 196, 196, 0.4);
`
const spStyle = `
padding: 20px;
border-bottom: 1px solid rgba(196, 196, 196, 0.4);
`
const StyledComponent = styled(Component)`
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
